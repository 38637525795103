<template>
    <div v-show="modalShow" style="position: fixed; top: 0; left: 0; z-index: 1032; width: 100%; height: 100%;">

        <div class="noselect d-flex justify-content-center align-items-center mainGalleryWindow" style="background: rgba(0,0,0,0.65); backdrop-filter: blur(15px);" @touchstart="touchStart" @touchend="detectSwipe">
            <div class="w-100 h-100 d-flex align-items-center justify-content-center">
                <div v-for=" (file, id) in attachments" :key="'image_' + file.id + '_' + id">
                    <div  v-show="id === currentId">
                        <div class="d-flex justify-content-center" v-if="file.type.split('/')[0]==='image'">
                            <b-img class="image-fullscreen-gallery" :src="file.url" style="z-index: 1033 !important;"></b-img>
                        </div>
                        <div class="w-full" v-else-if="file.type === 'application/pdf'">
                            <div class="d-flex justify-content-center" style="z-index: 1033 !important;" >
                                <div class="pdfViewer" style="cursor: pointer; overflow-y: auto;">
                                    <pdf
                                        :src="file.url"
                                        v-for="i in file.pages"
                                        :key="'pdf_preview' + i"
                                        :page="i"
                                        style="height: 100%"
                                    ></pdf>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="d-flex justify-content-center" style="z-index: 1033 !important;" >
                                <div class="emptyViewer">
                                    <div style="background: #dedede; border-radius: 10px; height: 100%; width: 100%">
                                        <div>
                                        </div>
                                        <div class="d-flex justify-content-center align-items-center w-100 h-100">
                                            <div>
                                                <div class="d-flex justify-content-center ">
                                                    <feather-icon icon="FileIcon" size="100" color="#b7b7b7"/>
                                                </div>
                                                <div class="mt-2 w-100 text-center" style="font-size: 15px; font-weight: bolder;">
                                                    {{$t('config.logos.preview_unavailable')}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <div style="color: #eeeeee; font-size: 10px; padding-top: 5px; padding-left: 3px; width: 100px;">
                                {{$t('config.logos.file')}} {{id + 1}} {{$t('config.logos.of')}} {{attachments.length}}
                            </div>
                            <div style="color: #eeeeee; font-size: 10px; padding-top: 5px; padding-right: 3px; max-width: 60%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                                {{file.name}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Close Button -->
        <div style="z-index: 1111; position: fixed; top: 20px; right: 20px; cursor: pointer;" @click="close">
            <feather-icon icon="XIcon"  style="z-index: 5555 !important;" size="50" color="#e16868"/>
        </div>

        <!-- Left Button -->
        <svg v-if="attachments && attachments.length >= 2" class="more-arrows leftArrow d-none d-md-inline" @click="nextImage('left')">
            <polygon class="arrow-top" style="fill: white !important;" points="37.6,27.9 1.8,1.3 3.3,0 37.6,25.3 71.9,0 73.7,1.3 "/>
            <polygon class="arrow-middle" style="fill: white !important;" points="37.6,45.8 0.8,18.7 4.4,16.4 37.6,41.2 71.2,16.4 74.5,18.7 "/>
            <polygon class="arrow-bottom" style="fill: white !important;" points="37.6,64 0,36.1 5.1,32.8 37.6,56.8 70.4,32.8 75.5,36.1 "/>
        </svg>

        <!-- Right Button -->
        <svg v-if="attachments && attachments.length >= 2" class="more-arrows rightArrow  d-none d-md-inline" @click="nextImage('right')">
            <polygon class="arrow-top" style="fill: white !important;" points="37.6,27.9 1.8,1.3 3.3,0 37.6,25.3 71.9,0 73.7,1.3 "/>
            <polygon class="arrow-middle" style="fill: white !important;" points="37.6,45.8 0.8,18.7 4.4,16.4 37.6,41.2 71.2,16.4 74.5,18.7 "/>
            <polygon class="arrow-bottom" style="fill: white !important;" points="37.6,64 0,36.1 5.1,32.8 37.6,56.8 70.4,32.8 75.5,36.1 "/>
        </svg>
    </div>
</template>
<script>
    import { BImg} from 'bootstrap-vue'
    import pdf from 'vue-pdf'

    export default {
        components: {BImg, pdf},
        props: {
            attachments: {
                type: Array
            }
        },
        data() {
            return {
                modalShow: false,
                show: false,
                currentId: 0,
                swipeStart: 0,
                swipeDistance: 0
            }
        },
        methods:{
            nextImage(side) {
                if (side === 'right') {
                    if (this.currentId >= this.attachments.length - 1) {
                        this.currentId = 0
                    } else {
                        this.currentId++
                    }
                }
                if (side === 'left') {
                    if (this.currentId <= 0) {
                        this.currentId = this.attachments.length - 1
                    } else {
                        this.currentId--
                    }
                }
            },
            touchStart(event) {
                this.swipeStart = event.changedTouches[0]
            },
            detectSwipe(event) {

                const swipeEnd = event.changedTouches[0]
                if (Math.abs(this.swipeStart.pageX - swipeEnd.pageX) > 150) {
                    if (swipeEnd.pageX - this.swipeStart.pageX > 0) {
                        this.nextImage('left')
                    } else {
                        this.nextImage('right')
                    }
                } else {
                    const img = event.target

                    const cords = event.changedTouches[0]
                    const srcEle = {
                        x: event.srcElement.clientHeight - event.srcElement.offsetLeft,
                        y: event.srcElement.clientWidth - event.srcElement.offsetLeft
                    }

                    if (img && img.tagName === 'IMG') {

                        if (srcEle.x / 2 <= cords.clientX) {
                            this.nextImage('right')
                        } else {
                            this.nextImage('left')
                        }

                    }
                }
            },
            async open(id) {
                await document.body.classList.add('modal-open')
                this.modalShow = true
                this.currentId = id
                this.show = true
            },
            close() {
                document.body.classList.remove('modal-open')
                this.image = ''
                this.show = false
                this.modalShow = false
                this.$emit('close')
            }
        }
    }
</script>
<style lang="scss">
.swiper-pagination-bullet-active{
  background: #72a5d8 !important;
}


.swiper-pagination-bullet{
  background: #98bfe9;
  width: 17px;
  height: 17px;
  margin-left: 5px ;
  margin-right: 5px;
}

.swiper-pagination{
  // margin-top: 10px !important;
  position: relative;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  // align-items: end;
  height: 30px;
}

.swiper-button-next {
  width: 35px;
  top: 50%;
  right: 0;
  margin-left: 25%;
}

.swiper-button-prev {
  width: 35px;
  top: 50%;
  left: 0;
  margin-right: 25%;
}

.swiper-button-next::after {
  padding: 10px;
  height: 60px;
  font-size: 40px !important;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: white;
  background: white !important;
  color: black;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  z-index: 1100;
}
.swiper-button-prev::after {
  padding: 10px;
  height: 60px;
  font-size: 40px !important;
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: white;
  // width: 100%;
  background: white !important;
  color: black;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  z-index: 1100;
}


.arrow {
  border: solid black;
  border-width: 0 7px 7px 0;
  display: inline-block;
  padding: 20px;
}

.text-bottom-right {
  padding: 10px;
  text-align: right;
  font-size: 1.5rem;
  // position: absolute;
  // right: 30px;
  // bottom: 10vh;
}

.mainGalleryWindow{
  height: 100%;
  width: 100%;
}

@media only screen and (min-width: 1200px) {
  .pdfViewer{
    height: 70vh;
    width: 60vw;
  }

  .emptyViewer{
    height: 60vh;
    width: 60vw;
  }

  .rightArrow {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    position: absolute;
    right: 30px;
    top: calc(50vh - 75px);
    cursor: pointer;
  }

  .leftArrow{
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    position: absolute;
    left: 30px;
    top: calc(50vh - 75px);
    cursor: pointer;
  }
  .image-fullscreen-gallery{
    margin-top: 20px;
    max-height: 60vh;
    max-width: 60vw;
  }
}

@media only screen and (max-width: 500px) {
  .image-fullscreen-gallery{
    // margin-top: 6em;
    height: 90vh;
    width: 90vw !important;
  }

  .pdfViewer{
    height: 45vh;
    width: 90vw;
  }

  .emptyViewer{
    height: 40vh;
    width: 90vw;
  }

}

@media only screen and (min-width: 501px) and (max-width: 1199px) {
  .image-fullscreen-gallery{
    // margin-top: 6em;
    height: 80vh;
    width: 70vw;
  }

  .pdfViewer{
    height: 55vh;
    width: 70vw;
  }

  .emptyViewer{
    height: 60vh;
    width: 70vw;
  }


}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* Arrow & Hover Animation */

.more-arrows {
  width: 75px;
  height: 65px;

  &:hover {

    polygon {
      fill: #FFF;
      transition: all .2s ease-out;

      &.arrow-bottom {
        transform: translateY(-18px);
      }

      &.arrow-top {
        transform: translateY(18px);
      }

    }

  }

}

polygon {
  fill: #FFF;
  transition: all .2s ease-out;

  &.arrow-middle {
    opacity: 0.75;
  }

  &.arrow-top {
    opacity: 0.5
  }
}
</style>

