import i18n from '@/i18n/i18n'
import {MessageType} from '@/libs/enums/Messages'

export const typeOptions = [
    {value:'version', icon:'CpuIcon', name:`${i18n.t('version.version')}`, variant: 'secondary'},
    {value:'changelog', icon:'BookIcon', name:`${i18n.t('print.message.changes_backlog')}`, variant: 'secondary'},
    {value:'warning', icon:'ShieldIcon', name:`${i18n.t('print.message.warning')}`, variant: 'warning'},
    {value:'message', icon:'MailIcon', name:`${i18n.t('print.message.message')}`, variant: 'secondary'},
    {value:'expiration', icon:'TrendingDownIcon', name:`${i18n.t('print.message.expiration')}`, variant: 'warning'},
    {value:'finance', icon:'DollarSignIcon', name:`${i18n.t('print.message.finances')}`, variant: 'secondary'}
]

export const priorityOptions = [
    {value:'info', icon:'InfoIcon', name:`${i18n.t('print.message.info')}`, variant: 'info'},
    {value:'warning', icon:'ShieldIcon', name:`${i18n.t('print.message.warning')}`, variant: 'warning'},
    {value:'danger', icon:'AlertTriangleIcon', name:`${i18n.t('print.message.danger')}`, variant: 'danger'},
    {value:'success', icon:'CheckSquareIcon', name:`${i18n.t('print.message.success')}`, variant: 'success'}
]


export const getVariant = (client) => {
    if (client.read) {
        if (client.read) return 'primary'
    }
    return 'warning'
}

export const getPriority = (val) => {

    const item = priorityOptions.find(priority => priority.value === val)

    if (!item) {
        return {
            icon: '',
            colour: '',
            name: '',
            variant: ''
        }
    }

    return item
}

export const getType = (val) => {
    const item = typeOptions.find(type => type.value === val)

    if (!item) {
        return {
            icon: '',
            colour: '',
            name: '',
            variant: ''
        }
    }
    return item
}

export const getTypeName = (val) => {
    const item = typeOptions.find(type => type.value === val)

    if (!item) {
        return ''
    }
    if (item.value === MessageType.Version) {
        return i18n.t('version.version')
    } else if (item.value === MessageType.ChangeLog) {
        return i18n.t('print.message.changes_backlog')
    } else if (item.value === MessageType.Warning) {
        return i18n.t('print.message.warning')
    } else if (item.value === MessageType.Message) {
        return i18n.t('print.message.message')
    } else if (item.value === MessageType.Expiration) {
        return i18n.t('print.message.expiration')
    } else if (item.value === MessageType.Finance) {
        return i18n.t('print.message.finances')
    } else return i18n.t('print.message.unknown')
}

export const getFileSize = (val) => {
    let em = 'B'
    let size = val

    if (size > 1024) {
        size = size / 1024
        em = 'KB'
    }

    if (size > 1024) {
        size = size / 1024
        em = 'MB'
    }

    if (size > 1024) {
        size = size / 1024
        em = 'GB'
    }

    size = Math.round(size * 100) / 100

    return `${size} ${em}`
}

export const getAuthor = (message) => {
    if (message.author === 'admin') {
        return i18n.t('general.author_admin')
    } else {
        return i18n.t('general.author_generated')
    }
}